<template>
  <div class="v_cash_out">
    <head-back>
        <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>申请提现</span>
      </template>
    </head-back>
    <div class="v-cash-out-container">
      <p class="v-cash-out-tips">
        <span>提现说明：</span>提现最低<span>10</span>元起提,提现100元起免手续费。
      </p>

      <div class="v-cash-out-id">
        <span>{{form.account}}</span>
      </div>

      <ul class="v-cash-out-list">
        <!-- <li class="v-cash-out-item g-flex-align-center v-cash-out-item-cell">
          <div class="v-change-info-form-item-box g-flex-align-center">
            <span class="v-change-info-form-item-label">提现资金类型:</span>
            <van-radio-group v-model="form.type" direction="horizontal" checked-color="#F9436B" @change="radioChange">
              <van-radio :name=1>本金</van-radio>
              <van-radio :name=2>佣金</van-radio>
            </van-radio-group>
          </div>
        </li> -->
        <li class="v-cash-out-item g-flex-align-center">
          <van-field oninput="value=value.replace(/[^0-9.]/g,'')" readonly  v-model="userInfo.principal" label="余额：" type="number" placeholder="请输入提现金额"/>
        </li>
        <!-- <li class="v-cash-out-item g-flex-align-center">
          <van-field oninput="value=value.replace(/[^0-9.]/g,'')"  v-model="userInfo.commission" label="佣金余额：" type="number" placeholder="请输入提现金额"/>
        </li> -->

        <li class="v-cash-out-item g-flex-align-center">
          <van-field oninput="value=value.replace(/[^0-9.]/g,'')"  v-model="form.money" label="提现金额：" type="number" placeholder="请输入提现金额">
             <template #button>
               <p class="v-cash-out-item-all" @click.stop="allCashClick">全部</p>
            </template>
          </van-field>
        </li>
        <li class="v-cash-out-item g-flex-align-center">
          <van-field v-model="form.password" label="登录密码：" type="password" placeholder="请输入登录密码" clearable/>
        </li>
      </ul>

      <div class="v-cash-out-btn">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
      </div>
    </div>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import { apiAddCash, apiGetUserInfo } from '@/utils/api.js'
  export default {
    components: { HeadBack, OperationButton },
    data() {
      return {
        cashType : this.$route.query.type, //支付宝 还是 银行卡提现
        userInfo: this.$global.userInfo,
        form: {
          id: '',
          account: '',
          money: '',
          password: ''
        },
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '申请提现',
          event: 'apiAddCashHandel'
        }
      }
    },
    created() {
      if(this.cashType == 1) {
        if(this.userInfo.bank) {
          if(this.userInfo.bank.status == 2) {
            this.form.id = this.userInfo.bank.id
            this.form.account = this.userInfo.bank.card_number
          }
        }
      }else {
        if(this.userInfo.alipay) {
          if(this.userInfo.alipay.status == 2) {
            this.form.id = this.userInfo.alipay.id
            this.form.account = this.userInfo.alipay.card_number
          }
        }
      }
    },
    methods: {
      // 变化
      radioChange() {
        this.form.money = ''
      },
      // 点击全部
      allCashClick() {
        this.form.money = this.userInfo.principal
      },
      // 申请提现
      async apiAddCashHandel() {
        if(!this.form.money) return this.$toast('请输入提现金额')
        if(!this.form.password) return this.$toast('请输入登录密码')
        if(this.form.type == 2 && Number(this.form.money) < 50) return this.$toast('佣金提现最少50元起')
        this.$global.loadingShow = true
        const { success, data } = await apiAddCash(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
      },
      
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },

      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.$global.setUserInfo(data.data)
        this.userInfo = data.data
        this.$router.go(-1)
      },
    }
  }
</script>

<style lang="scss">
.v_cash_out {
  height: 100%;
  background: #F1F1F1;
  .v-cash-out-container {
    .v-cash-out-tips {
      color: #959AA3;
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 20px;
      padding: 12px 24px;
      text-align: justify;
      span {
        color: $main_color;
      }
    }

    .v-cash-out-id {
      background: $white;
      padding: 18px 20px;
      span {
        color: #292929;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    .v-cash-out-list {
      background: $white;
      margin-top: 14px;
      .v-cash-out-item {
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-cash-out-item-all {
          color: $main_color;
          padding: 0 10px;
        }
        .v-change-info-form-item-box {
          width: 100%;
          padding: 10px 16px;
          .v-change-info-form-item-label {
            flex: 1;
            color: #646566;
            text-align: left;
            font-size: 14px;
          }
          .van-radio-group {
            flex: 2;
            font-size: 14px;
          }
        }
        .van-cell {
          .van-cell__title  {
            margin-right: 0;
            flex: 1;
            color: #646566;
          }
          .van-cell__value {
            flex: 2;
            text-align: left;
            .van-field__control {
              color: #969799;
            }
          }
        }
      }
    }

    .v-cash-out-btn {
      margin-top: 20px;
      padding: 0 10px;
    }
  }
}
</style>